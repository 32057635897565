import React from "react";
import css from './Special.module.css';

const Special = () => {
  
  const openGoogleForm = () => {
    const googleFormUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfewXWg7VVMK0QumPu2lXGr90yVYcYhoTG4JaV-sHo0EzLxxQ/viewform";

    window.open(googleFormUrl, "_blank");
  };

  return (
    <div className={css.specialContainer}>
      <h1>Three Steps To Get A FREE Consultation Call From Us</h1>
      <h3>
      Let me & my team help you to scale your business with our proven marketing strategies.
      </h3>

      <div className={css.specialFlex}>
        <div className={css.specialBox}>
            <h1>1</h1>
            <p>Click on the button below.</p>
        </div>

        <div className={css.specialBox}>
            <h1>2</h1>
            <p>Fill Out The Application Form.</p>
        </div>
 
        <div className={css.specialBox}>
            <h1>3</h1>
            <p>Our team will get in touch with you.</p>
        </div>
      </div>
      <button onClick={openGoogleForm}>
      Grab Your Free Call Now
      </button>
    </div>
  );
};

export default Special;
