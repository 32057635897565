import './App.css';
import Banner from './Components/Banner/Banner';
import Dedicated from './Components/Dedicated/Dedicated';
import DedicatedNew from './Components/DedicatedNew/DedicatedNew'
import Founder from './Components/Founder/Founder';
import Home from './Components/Home';
import Ruthless from './Components/Ruthless/Ruthless';
import Services from './Components/Services/Services';
import Special from './Components/Special/Special';

function App() {
  return (
    <>
    <Banner/>
      <Home/>
      <Dedicated/>
      <Services/>
      <Special/>
      <Ruthless/>
      <Founder/>
    </>
  );
}

export default App;
