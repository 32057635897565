import React from "react";
import YouTube from "react-youtube";
import css from "./FounderNews.module.css";
import news1 from "../../../Resources/news-1.avif";
import news2 from "../../../Resources/news-2.avif";
import news3 from "../../../Resources/news-3.avif";

const FounderNews = () => {
  const videoId1 = "8-pzgrNpAHs";
  const videoId2 = "sAUSJPCTFD4";
  // const opts = {
  //     height: '315',
  //     width: '560',
  //     playerVars: {
  //       // YouTube video parameters (optional)
  //       autoplay: 1,
  //     },
  //   };

  return (
    <div>
      <h1 className={css.heading}>Newspaper Coverage</h1>
      <div className={css.FounderNewsBox}>
        <div className={css.NewsBox}>
          <img src={news1} alt="Newspaper clip" />
        </div>

        <div className={css.NewsBox}>
          <img src={news2} alt="Newspaper clip" />
        </div>

        <div className={css.NewsBox}>
          <img src={news3} alt="Newspaper clip" />
        </div>
      </div>

      <h1 className={css.heading}>News & Tv Coverage</h1>
      <div className={css.VideoContainer}>
      <div className={css.videoCard}>
            <iframe
              width="560"
              height="515"
              src="https://www.youtube.com/embed/8-pzgrNpAHs?si=bM5xQTfaNn5HlCO0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>

      
          <div className={css.videoCard}>
            <iframe
              width="560"
              height="515"
              src="https://www.youtube.com/embed/sAUSJPCTFD4?si=mAak_DOIoItgpQVy"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>

      </div>
    </div>
  );
};

export default FounderNews;
