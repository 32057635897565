import React from "react";
import css from "./Founder.module.css";
import ic1 from "../../Resources/ic-1.avif";
import ic2 from "../../Resources/ic-2.avif";
import ic3 from "../../Resources/ic-3.avif";
import ic4 from "../../Resources/ic-4.avif";
import ic5 from "../../Resources/ic-5.avif";
import ic6 from "../../Resources/ic-6.avif";
import logo1 from "../../Resources/1.avif";
import logo2 from "../../Resources/2.avif";
import logo3 from "../../Resources/3.avif";
import logo4 from "../../Resources/4.avif";
import logo5 from "../../Resources/5.avif";
import logo6 from "../../Resources/6.avif";
import logo7 from "../../Resources/7.avif";
import logo8 from "../../Resources/8.avif";
import logo9 from "../../Resources/9.avif";
import logo10 from "../../Resources/10.avif";
import FounderNews from "./FounderNews/FounderNews";

const Founder = () => {
  return (
    <>
    <div className={css.FounderContainer}>
      <div className={css.FounderTop}>
        <div className={css.iconsBox}>
          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic1} alt="icon" />
            </div>

            <p>Forbes-Recognised Digital Marketing Expert.</p>
          </div>

          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic2} alt="icon" />
            </div>

            <p>Over a Decade of Transforamtive Experience.</p>
          </div>

          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic3} alt="icon" />
            </div>

            <p>Guided 300,000 Towards Thriving Self-Employment.</p>
          </div>

          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic4} alt="icon" />
            </div>

            <p>Mastermind behind 9+ Crore Earnings.</p>
          </div>

          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic5} alt="icon" />
            </div>

            <p>Trusted Mentor Across 5+ Countries.</p>
          </div>

          <div className={css.iconCard}>
            <div className={css.icon}>
              <img src={ic6} alt="icon" />
            </div>

            <p>newx: India's A List.</p>
          </div>
        </div>

        <div className={css.AboutFounder}>
          <h1>About Founder</h1>
          <div className={css.FounderBox}>
            <h4>Helloveeru</h4>
            <h1 className={css.veeru1}>Veerander </h1>
            <h1 className={css.veeru2}>Chowdary</h1>

            <h3>
              Digital Marketer, Entrepreneur, Sales Trainer & A Mentor <br />{" "}
              (Founder & CEO - WorkHours.in)
            </h3>

            <h5 className={css.area}>Hyderabad</h5>
            <h5>Telangana, 500072</h5>
          </div>

          <p>
            My name is Veerander Chowdary. I am an Entrepreneur, Digital
            Marketer, Mentor & a Sales Trainer. I have 7+ years of experience
            working in the digital marketing field and I know all ins and outs
            of the industry.
          </p>

          <div className={css.logoContainer}>
            <div className={css.logo}>
              <img src={logo1} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo2} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo3} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo4} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo5} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo6} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo7} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo8} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo9} alt="Logo" />
            </div>

            <div className={css.logo}>
              <img src={logo10} alt="Logo" />
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <FounderNews/>
    </>
  );
};

export default Founder;
