import React from 'react';
import css from './Banner.module.css'

const Banner = () => {
  return (
    <div className={css.scrollingbanner}>
   
    <h1>Rebranding Announcement:Exciting News! FindBusy is Now Trydo Agency.</h1>
  </div>
  )
}

export default Banner