import React from "react";
import logo from "../../../src/Resources/findbusy LP logo.avif";
import css from "../Hero/Hero.module.css";
import Image from "../../../src/Resources/Screenshot 2024-01-19 110318.avif";
import { FaRegCirclePlay } from "react-icons/fa6";
import logo1 from "../../../src/Resources/1.avif";
import logo2 from "../../../src/Resources/2.avif";
import logo3 from "../../../src/Resources/3.avif";
import logo4 from "../../../src/Resources/4.avif";
import logo5 from "../../../src/Resources/8.avif";
import line from "../../../src/Resources/red line.avif";
import ReactPlayer from 'react-player';


const Hero = () => {
  const openGoogleForm = () => {
    const googleFormUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfewXWg7VVMK0QumPu2lXGr90yVYcYhoTG4JaV-sHo0EzLxxQ/viewform";

    window.open(googleFormUrl, "_blank");
  };

  const vimeoVideoUrl = 'https://vimeo.com/904735094?share=copy';


  return (
    <div className={css.HeroSection}>
      <div className={css.logo}>
        <img src={logo} alt="FindBusy Logo" />
      </div>

      <h1>
        DIGITAL MARKETING AGENCY FOR <span>STARTUPS</span>
      </h1>
      <p>
        Hello StartUps, Meet Findbusy, Where Startup Stories Unfold On A Canvas
        Of Growth. Crafting Your Digital Success Symphony
      </p>

      <button onClick={openGoogleForm}>Book A Free Call With Us</button>

      <div className={css.VeeruImage}>
        <ReactPlayer
        url={vimeoVideoUrl}
        width="100%"  
        height="100%" 
        controls 
        playing={true}
        // style={{borderRadius:"0.5vw", border:"1px solid grey"}}
      />
      </div>

      <div className={css.desktop}>
        <div className={css.logos}>

          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo1} alt="ZEE logo" />
          </div>
          </div>
         

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>

          <div className={css.NewsBox}>

          <div className={css.Newslogo}>
            <img src={logo2} alt="DC Logo" />
          </div>
          </div>

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>


          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo3} alt="Mid-Day Logo" />
          </div>
          </div>

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>


          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo4} alt="Founder India Logo" />
          </div>
          </div>

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>


          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo5} alt="BW Logo" />
          </div>
          </div>
        </div>
      </div>

      <div className={css.mobile}>
        <div className={css.logosMobile}>

        <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo1} alt="ZEE logo" />
          </div>
          </div>

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>

          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo2} alt="DC Logo" />
          </div>
          </div>

          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo3} alt="Mid-Day Logo" />
          </div>
          </div>

          <div className={css.logoLine}>
            <img src={line} alt="line" />
          </div>

<div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo4} alt="Founder India Logo" />
          </div>
          </div>

          <div className={css.NewsBox}>
          <div className={css.Newslogo}>
            <img src={logo5} alt="BW Logo" />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;






