import React from 'react';
import css from './Ruthless.module.css';

const Ruthless = () => {
  return (
    <div className={css.ruthlessContainer}>
        <h1>What makes us standout from the competition?</h1>
        <h3>These numbers are just a tip of the iceberg.</h3>

        <div className={css.RuthlessFlex}>
            <div className={css.RuthlessCard}>
                <h1>10+</h1>       
                <p>Clients</p>
                <div></div>
                <div></div>
            </div>

            <div className={css.RuthlessCard}>
                <h1>10 Cr+</h1>
                <p> Ad Budget Handled</p>
            </div>

            <div className={css.RuthlessCard}>
                <h1>3.5x</h1>
                <p>of ROI Generated</p>
            </div>

            <div className={css.RuthlessCard}>
                <h1>110+ </h1>
                <p>Campaigns Executed</p>
            </div>
        </div>
       
    </div>
  )
}

export default Ruthless