import React from 'react';
import Hero from './Hero/Hero';

const Home = () => {
  return (
    <div>
      <Hero/>
    </div>
  )
}

export default Home