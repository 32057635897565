import React from "react";
import css from "../Services/Services.module.css";
import i1 from "../../Resources/icon-1.avif";
import i2 from "../../Resources/icon-2.avif";
import i3 from "../../Resources/icon-3.avif";
import i4 from "../../Resources/icon-4.avif";
import i5 from "../../Resources/icon-5.avif";
import i6 from "../../Resources/icon-6.avif";
import i7 from "../../Resources/icon-7.avif";

const Services = () => {
  const openGoogleForm = () => {
    const googleFormUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfewXWg7VVMK0QumPu2lXGr90yVYcYhoTG4JaV-sHo0EzLxxQ/viewform";

    window.open(googleFormUrl, "_blank");
  };
  return (
    <div className={css.ServicesContainer}>
      <h1>Our Services</h1>
      <p className={css.subheading}>
        Transform your Expectations With Our Dedicated Services
      </p>

      <div>
        <div className={css.ServicesCards1}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i1} alt="Business Consulting" />
            </div>
            <h3>01</h3>
          </div>

          <h4>Business Consulting</h4>
          <p>
            Business consulting involves helping business owners and companies
            troubleshoot and problem-solve. Learn about business consulting
            services
          </p>
        </div>
      </div>
      <div className={css.ServicesCardss}>
        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i2} alt="Branding" />
            </div>
            <h3>02</h3>
          </div>

          <h4>Branding</h4>
          <p>
            Branding is the process of creating a distinct identity for a
            business in the minds of your target audience and the general
            population.
          </p>
        </div>

        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i3} alt=" SEO" />
            </div>
            <h3>03</h3>
          </div>

          <h4> SEO</h4>
          <p>
            A knowledge of basic SEO can have a noticeable impact. Explore the
            Google SEO starter guide for an overview of search engine
            optimization essentials.
          </p>
        </div>

        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i4} alt="Paid Marketing" />
            </div>
            <h3>04</h3>
          </div>

          <h4>Paid Marketing</h4>
          <p>
            With paid marketing, you purchase advertising that allows you to
            specifically target audiences, instead of waiting for them to find
            you organically.
          </p>
        </div>

        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i5} alt="Performance Marketing" />
            </div>
            <h3>05</h3>
          </div>

          <h4>Performance Marketing</h4>
          <p>
            Performance marketing campaigns give you the ability to measure
            everything from brand awareness to conversion rate down.
          </p>
        </div>

        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i6} alt="Social media optimization" />
            </div>
            <h3>06</h3>
          </div>

          <h4>Content Marketing / Social Media Optimisation</h4>
          <p>
            Social media optimization involves creating unique content for a
            social media site.
          </p>
        </div>

        <div className={css.ServicesCards}>
          <div className={css.ServicesCard}>
            <div className={css.ServicesCardLogo}>
              <img src={i7} alt="Web development" />
            </div>
            <h3>07</h3>
          </div>

          <h4>Web development</h4>
          <p>
            Web development refers to building and maintaining websites. It
            includes web design, web publishing, web programming, and database.
          </p>
        </div>
      </div>

      <div className={css.servicesContainer2}>
        <h1>Watch the most critical 45-minute training for your business.</h1>
        <button className={css.serviceButtons} onClick={openGoogleForm}>
          Grow Your Business Now
        </button>
      </div>
    </div>
  );
};

export default Services;
