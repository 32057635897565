import React from "react";
import css from "../Dedicated/Dedicated.module.css";
import gmail from "../../../src/Resources/gmail.avif";
import icons from "../../../src/Resources/mail icons.avif";
import external from "../../../src/Resources/inbox.avif";
import fb from "../../../src/Resources/dp.avif";
import sideIcons from "../../../src/Resources/side icons.avif";

const Dedicated = () => {
  return (
    <div className={css.dedicatedContainer}>
      <div className={css.gmail}>
        <img src={gmail} alt="Gmail" />
      </div>
      <div className={css.mailIcons}>
        <img src={icons} alt="mail icons" />
      </div>

      <div className={css.dedicatedSubject}>
        <h1> Our Niche, Our Passion</h1>
        <div className={css.mailLogos}>
          <img src={external} alt="mailLogos" />
        </div>
      </div>

      <div className={css.findBusy}>
        <div className={css.findBusyLogo}>
          <div className={css.fbLogo}>
            <img src={fb} alt="Find Busy Logo" />
          </div>
          <h1>FindBusy</h1>
          <p> &lt;Marketing Agnecy For StartUps&gt;</p>
        </div>

        <div className={css.fbmail}>
          <img src={sideIcons} alt="Side Icons" />
        </div>
      </div>

      <div className={css.DedicatedContent}>
        <h1>Dear StartUps,</h1>
        <p>
          At Findbusy, startups are not just our clients; they're our passion.
          We're designed from the ground up to be your dedicated partner in
          digital success.
          </p>
          <p>
            From launch to growth, we're here for every startup journey,
            offering expertise, creativity, and a genuine commitment to your
            success. Let's build something extraordinary together.
          </p>
          <p>Regards</p>
          <p className={css.tfb}>Team Findbusy</p>
      </div>
    </div>
  );
};

export default Dedicated;
